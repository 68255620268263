/* Table.css */

/* Table header styles */

.table thead tr th {
  background-color: #ed6525; /* Branding color for header */
  color: #fff; /* White text color */
  padding: 10px;
}

/* Table body styles */
.table tbody tr:nth-child(even) {
  background-color: #f8e8e3; /* Light shade of branding color for even rows */
}

.table tbody tr:nth-child(odd) {
  background-color: #f5d5c8; /* Dark shade of branding color for odd rows */
}

/* Table cell styles */
.table tbody tr td {
  padding: 10px;
}

/* Status cell styles */
/* .table tbody tr td:last-child {
  font-weight: bold;
} */

.table tbody tr td:last-child.win {
  color: #ed6525; /* Branding color for "Win" status */
}

.table tbody tr td:last-child.lose {
  color: #c0392b; /* Red color for "Lose" status */
}


.table {
  margin: auto;
  text-align: center;
}

.table th, .table td {
  text-align: center;
  vertical-align: middle;
}



.table-container {
  margin: auto;
  text-align: center;
}

.table {
  margin: auto;
  text-align: center;
}

.table th, .table td {
  text-align: center;
  vertical-align: middle;
}

.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 1.5rem;
}
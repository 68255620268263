/* @media (max-width: 767px) {
    .logo-wrapper {
      justify-content: center !important;
    }
  }


  

/* Reducing space between header and main content */



.header {
  position: fixed;
  width: 100%;
  background-color: white; /* Change the header background to white */
  color: #e76a20; /* Updated Orange color for text */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 50;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
  position: relative; /* Ensures absolute positioning within the header-container works correctly */
}

.logo-wrapper {
  display: flex;
  align-items: center;
}

.site-logo img {
  height: 50px;
  width: auto;
  margin-right: 10px;
}

.site-logo .flag {
  height: 24px;
  width: auto;
  margin-left: 10px;
  vertical-align: middle;
}

.site-logo span {
  font-size: 24px;
  vertical-align: middle;
}

nav {
  display: flex;
  gap: 1.5rem;
}

nav a {
  color: white;
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem 1rem;
  background-color: #e76a20; /* Orange background for the links */
  border-radius: 5px;
}

nav a:hover {
  background-color: #d35400; /* Darker shade on hover */
  color: white;
}

.menu-toggle, .menu-close {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #e76a20; /* Orange color */
  cursor: pointer;
  position: absolute; /* Positioning the button absolutely within the header-container */
  right: 1rem; /* Aligning it to the right side */
  top: 1rem; /* Ensuring it's aligned vertically within the header */
}

/* Responsive design */
@media (max-width: 768px) {
  .header-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .logo-wrapper {
    flex: 1;
  }

  .logo-wrapper.hide {
    display: none;
  }

  nav {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    display: none;
  }

  nav.nav-open {
    display: flex;
  }

  .menu-toggle {
    display: block;
  }

  .menu-close {
    display: block;
  }

  .menu-close {
    display: none;
  }

  .nav-open ~ .menu-toggle {
    display: none;
  }

  .nav-open ~ .menu-close {
    display: block;
  }
}

@media (max-width: 480px) {
  .header-container {
    padding: 1rem;
  }

  .site-logo img {
    height: 40px;
  }

  .site-logo span {
    font-size: 20px;
  }

  nav a {
    font-size: 14px;
  }
}




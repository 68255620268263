/* OtherPage.css */
.container {
    text-align: center;
}

.flags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

.flag {
    width: 100px; /* Set a standard width for all flags */
    transition: transform 0.3s ease-in-out; /* Smooth transformation on hover */
}

.flag:hover {
    transform: scale(1.1); /* Slightly enlarge flags on hover */
    cursor: pointer; /* Change cursor on hover to indicate interactivity */
}

.heading {
    margin-top: 20px;
    color: #333; /* Dark grey text for better readability */
    font-size: 1.5em;
}


/* Adjustments for smaller screens */
@media (max-width: 600px) {
    .flag {
        width: 80px; /* Smaller flags on smaller screens */
    }
    .heading {
        font-size: 1.2em; /* Smaller heading font size */
    }
}
